@import 'src/styles/variables/variables';

@include useTheme();

.Root {
  position: relative;
  overflow: hidden;
  height: 1140px;
  background: #D3A88B;
  
  @include min-width(medium-tablet) {
    background: none;
    height: auto;
    max-height: 1016px;
  }
}

.Container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 30px 17px;

  p {
    margin-bottom: 0;
  }

  @include min-width(small-tablet) {
    padding: 59px 49px 0;
    overflow: hidden;
  }

  @include min-width(small-desktop) {
    padding: 30px 49px 0;
  }

  @include min-width(large-desktop) {
    padding: 86px;
    padding-left: 90px;
  }

  @include min-width(xlarge-desktop) {
    padding-left: 60px;
  }
}

.BgImage {
  display: none;

  @include min-width(medium-tablet) {
    display: block;
    width: 100%;
    height: auto;
  }
}

.PricingCards {
  padding: 50px 20px;

  @include min-width(large-tablet) {
    padding: 67px 0 !important;
  }
}

.isNewHomePage {
  .PricingCards {
    padding: 0;
  }
}

.CTA {
  @include max-width(large-tablet) {
    span {
      display: none;
    }
  }
}
