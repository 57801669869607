@import 'src/styles/variables/variables';

@include useTheme();

@mixin toggleBehavior {
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: $transition;

  &.open {
    visibility: visible;
    opacity: 1;
    height: auto;
    transition: $transition;
  }
}

.Stamp {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

.Card {
  text-align: left;
  width: 264px;
  position: relative;

  @include min-width(medium-tablet) {
    width: 280px;
  }

  @include min-width(desktop) {
    width: 320px;
  }

  @include min-width(medium-desktop) {
    width: 345px;
  }

  .CardContainer {
    position: relative;
  }

  .Content {
    background-color: $core2;
    padding: 22px 24px 25px;
    border: none;

    @include min-width(desktop) {
      padding: 18px 24px 28px;
    }

    @include min-width(medium-desktop) {
      padding: 34px 21px 23px 38px;
    }
  }

  .Pricing {
    margin-bottom: 18px;
    text-transform: none;
  }

  @mixin basePriceStyle {
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'clig' off, 'liga' off;
    @include secondaryFont(400);
    font-size: 35px;
    font-style: normal;
    line-height: 45px;
    letter-spacing: -0.4px;
  }

  .PriceLabel {
    margin-bottom: 5px;
    min-height: 24px;

    @include min-width(small-tablet) {
      font-size: 13px;
      margin-bottom: -7px;
    }

    @include min-width(desktop) {
      font-size: 16px;
      margin-bottom: 7px;
    }

    @include min-width(medium-desktop) {
      margin-bottom: 3px;
    }
  }

  .PriceBottom {
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 16px;

    @include min-width(small-tablet) {
      font-size: 12px;
      margin: -7px 0 -3px;
      letter-spacing: 0;
      white-space: nowrap;
    }

    @include min-width(medium-tablet) {
      min-height: 24px;
    }

    @include min-width(desktop) {
      font-size: 12px;
      margin: 4px 0 0;
    }

    @include min-width(medium-desktop) {
      margin: 3px 0 0;
      font-size: 13px;
      letter-spacing: .1px;
    }
  }

  .SalePrice {
    @include basePriceStyle;
    color: $core1;

    &.sale {
      color: $cinnamon;
    }

    @include min-width(tablet) {
      font-size: 28px;
    }

    @include min-width(desktop) {
      font-size: 35px;
    }
  }

  .FullPrice {
    position: relative;
    @include basePriceStyle;
    margin-right: 9px;
    color: $gray3;

    &:after {
      content: '';
      position: absolute;
      width: 105%;
      height: 2px;
      background-color: $gray3;
      left: 0;
      top: 50%;
      transform: rotate(-165deg);
    }

    @include min-width(tablet) {
      font-size: 28px;
    }

    @include min-width(desktop) {
      font-size: 35px;
    }
  }


  .CtaWidth {
    width: calc(100% + 18px);
    max-width: 279px;
    margin: 0 -9px 18px;
    min-height: 48px;
    font-size: 15px;

    @include min-width(small-tablet) {
      max-width: 218px;
      font-size: 15px;
      width: 100%;
      margin: 0 0 20px;
      min-height: 0;
      height: 48px;
    }

    @include min-width(desktop) {
      max-width: none;
      margin: 15px 0 22px;
    }

    @include min-width(medium-desktop) {
      margin: -4px 0 25px -9px;
      width: calc(100% + 9px);
    }
  }
}

.Title {
  @include secondaryFont(400);
  text-align: left;
  font-size: 28px;
  font-style: normal;
  line-height: 32px;
  letter-spacing: -0.4px;
  max-width: 170px;
  margin-bottom: 22px;

  @include min-width(medium-tablet) {
    font-size: 28px;
    line-height: 32px;
    max-width: 118px;
    margin-bottom: 18px;
  }

  @include min-width(medium-desktop) {
    font-size: 48px;
    line-height: 58px;
    max-width: 240px;
    margin-bottom: 21px;
  }
}

.UseCaseGrouping {
  @include primaryFont(400);
  margin-bottom: 29px;

  @include min-width(small-tablet) {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 22px;
  }

  @include min-width(desktop) {
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    min-height: 96px;
  }

  @include min-width(medium-desktop) {
    margin-bottom: 29px;
  }
}

$transition: visibility 0s, opacity 0.6s, height 0.3s ease-in-out;

.ToggleWrapper {
  display: flex;
  justify-content: center;

  @include min-width(medium-tablet) {
    display: block;
  }
}

.AvailableLocations {
  color: $core4 !important;
  padding: 0;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0;

  path {
    stroke: $core4;
    stroke-width: 0.8px;
  }
}

.AvailableLocationsContent {
  @include toggleBehavior;
  width: 100%;
  background: $core2;
  color: $core4;
  font-size: 12px;
  line-height: 18px;
  padding-top: 5px;
  text-align: center;

  @include min-width(medium-tablet) {
    text-align: left;
    position: absolute;
    height: 53px;
    left: 0;
    bottom: -70px;
    z-index: 1;
    padding: 0 15px 20px;
  }

  @include min-width(desktop) {
    bottom: -60px;
    padding: 0 15px 20px 24px;
  }

  @include min-width(medium-desktop) {
    bottom: -60px;
    padding: 0 15px 20px 38px;
  }
}

.Caret {
  margin: 0 0 2px 6px;
  transition: transform .4s;
  transform: rotate(180deg);

  &.Retracted {
    transform: rotate(0);
  }
}

